import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import authSlice from './Auth/authStore';
import logger from 'redux-logger';
import bonusSlice from './Bonus/bonusStore';
import triggerSlice from './Trigger/triggerStore';
import wheelSlice from './WheelGame/wheelStore';
import gamesSlice from './Games/gamesStore'
import jackpotSlice from './JackpotGame/jackpotStore';
import caseSlice from './CaseGame/caseStore'

const store = configureStore({
  reducer: {
    auth: authSlice,
    bonus: bonusSlice,
    trigger: triggerSlice,
    games: gamesSlice,
    wheel: wheelSlice,
    jackpot: jackpotSlice,
    case: caseSlice,
  },
  middleware: getDefaultMiddleware => process.env.NODE_ENV === 'development'
    ? getDefaultMiddleware().concat(logger)
    : getDefaultMiddleware()
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;