import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IJackpotPrizeList, IJackpotProfile } from "../../entities/jackpotGame";
import { getPrizeJackpot, getProfiles } from "./thunks";

interface JackpotState {
  list: {
    profiles: IJackpotProfile[],
    entities: IJackpotPrizeList[]
    loading: boolean;
  },
  profile?: {
    global: IJackpotProfile
  }
}

const initialState: JackpotState = {
  list: {
    loading: false,
    entities: [],
    profiles: [],
  },
}

export const jackpotSlice = createSlice({
  name: "jackpotGame",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getPrizeJackpot.pending, (state) => {
      state.list.loading = true;
    })
    builder.addCase(getPrizeJackpot.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.list.entities = action.payload.sort((a, b) => a.id - b.id);
      }
      state.list.loading = false;
    })

    builder.addCase(getProfiles.pending, (state) => {
      state.list.loading = true;
    })
    builder.addCase(getProfiles.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.list.profiles = action.payload.sort((a, b) => a.id - b.id);
      }
      state.list.loading = false;
    })
  },
});

// export const { } = jackpotSlice.actions;

export const selectJackpotList = (state: RootState) => state.jackpot.list;

export default jackpotSlice.reducer;
