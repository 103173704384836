import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrlV1 } from "../../config/config";
import { agent, encodeGetParams } from "../../api";
import {
  ElementOrder,
  HowToWinGlobal,
  IJackpotProfile,
  JackpotDatabaseContent,
  PopupSettingsForm,
  RequestNotificationGlobal,
  ResponseElementOrder,
  ResponseHowToWin,
  ResponseHowToWinlangs,
  ResponseJackpotList,
  ResponseJackpotMost,
  ResponseJackpotParticipate,
  ResponseJackpotPrize,
  ResponseNotification,
  ResponseNotificationLangs,
  ResponsePopupLangs,
  ResponsePopupSettings,
  ResponsePostContent,
  ResponsePostContentLang,
  ResponsePostGlobal,
} from "../../entities/jackpotGame";

const DEFAULT_URL = `${apiUrlV1}/organization/game/jackpot`;

//Получение списка джекпотов
export const getPrizeJackpot = createAsyncThunk(
  "jackpot/prize-list",
  async () => {
    const query = encodeGetParams({ page: 1, limit: 250 });
    const req = await agent.get(`${DEFAULT_URL}/list${query}`);
    if (req.ok) {
      const res = (await req.json()) as ResponseJackpotList;
      return res.jackpotPrizeList;
    }
    return [];
  }
);

//Получение глобальных настроект по id джекпота
export const getPrizeJackpotById = createAsyncThunk(
  "jackpot/get-jackpot-by-id",
  async (id: string) => {
    const req = await agent.get(`${DEFAULT_URL}/${id}`);
    if (req.ok) {
      const res = (await req.json()) as ResponseJackpotPrize;
      return res;
    }
    return undefined;
  }
);

//Получение глобальных настроект по id джекпота
export const postPrizeJackpotById = createAsyncThunk(
  "jackpot/post-jackpot-by-id",
  async (data: ResponsePostGlobal, thunksApi) => {
    const req = await agent.post(`${DEFAULT_URL}`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      const res = (await req.json()) as { id: number };
      thunksApi.dispatch(
        postElementOrder({
          data: [
            {
              title: "Description",
              position: 1,
            },
            {
              title: "Leaderboard",
              position: 2,
            },
            {
              title: "Participate in the jackpot",
              position: 3,
            },
            {
              title: "Most Chance to Win Jackpot",
              position: 4,
            },
            {
              title: "Rules",
              position: 5,
            },
          ],
          id: res.id,
        })
      );
      thunksApi.dispatch(getPrizeJackpotById(res.id.toString()));
      
      return res.id as number;
    }
    return undefined;
  }
);

//Получение глобальных настроект по id джекпота
export const patchPrizeJackpotById = createAsyncThunk(
  "jackpot/patch-jackpot-by-id",
  async (props: { data: ResponsePostGlobal; id: number }, thunksApi) => {
    const { data, id } = props;
    const req = await agent.patch(`${DEFAULT_URL}/${id}`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      const res = (await req.json()) as { id: number };
      thunksApi.dispatch(getPrizeJackpotById(res.id.toString()));
    }
  }
);

//Удаление джекпота по id
export const deletePrizeJackpotById = createAsyncThunk(
  "jackpot/delete-jackpot-by-id",
  async (id: string, thunksApi) => {
    const req = await agent.delete(`${DEFAULT_URL}/${id}`);
    if (req.ok) {
      thunksApi.dispatch(getPrizeJackpot());
      return null;
    }
    return null;
  }
);
//
export const getContentJacpotById = createAsyncThunk(
  "jackpot/get-content-by-id",
  async (id: string) => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/content-localization`);
    if (req.ok) {
      const res = (await req.json()) as ResponsePostContent;
      return res;
    }
  }
);
//
export const postContentById = createAsyncThunk(
  "jackpot/post-content-by-id",
  async (data: any) => {
    const req = await agent.post(
      `${DEFAULT_URL}/content-localization/create-global-settings`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      const res = (await req.json()) as {
        langs: ResponsePostContentLang[];
        globalSettings: JackpotDatabaseContent;
      };
      return res;
    }
  }
);

export const postContentTranslationById = createAsyncThunk(
  "jackpot/post-content-translation-by-id",
  async (data: any) => {
    const req = await agent.post(
      `${DEFAULT_URL}/content-localization/add-lang`,
      {
        body: JSON.stringify(data),
      }
    );
  }
);

export const patchContentTranslationById = createAsyncThunk(
  "jackpot/patch-content-translation-by-id",
  async (data: any) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/content-localization/update-lang`,
      {
        body: JSON.stringify(data),
      }
    );
  }
);

export const getPopup = createAsyncThunk(
  `jackpot/get-popup-settings`,
  async (id: number): Promise<ResponsePopupSettings | undefined> => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/popup-settings`);
    if (req.ok) {
      const res = (await req.json()) as ResponsePopupSettings;
      return res;
    }
    return undefined;
  }
);

export const postPopupSettings = createAsyncThunk(
  `jackpot/post-popup-settings`,
  async (data: { jackpotId: number; globalSettings: PopupSettingsForm }) => {
    await agent.post(`${DEFAULT_URL}/popup-settings`, {
      body: JSON.stringify(data),
    });
  }
);

export const patchPopupSettings = createAsyncThunk(
  `jackpot/patch-popup-settings`,
  async (data: { jackpotId: number; globalSettings: PopupSettingsForm }) => {
    await agent.patch(`${DEFAULT_URL}/popup-settings`, {
      body: JSON.stringify(data),
    });
  }
);

export const postPopupTranslationById = createAsyncThunk(
  "jackpot/post-popup-translation-by-id",
  async (props: { jackpotId: number; lang: ResponsePopupLangs }) => {
    await agent.post(`${DEFAULT_URL}/popup-settings/add-lang`, {
      body: JSON.stringify(props),
    });
  }
);

export const patchPopupTranslationById = createAsyncThunk(
  "jackpot/patch-popup-translation-by-id",
  async (props: { jackpotId: number; lang: ResponsePopupLangs }) => {
    await agent.patch(`${DEFAULT_URL}/popup-settings/update-lang`, {
      body: JSON.stringify(props),
    });
  }
);

//создание parcitipate
export const postParcitipateById = createAsyncThunk(
  "jackpot/post-parcitipate-by-id",
  async (props: { id: number; data: ResponseJackpotParticipate }) => {
    const req = await agent.post(`${DEFAULT_URL}/${props.id}/parcitipate`, {
      body: JSON.stringify(props.data),
    });
    if (req.ok) {
      const res = await req.json();
    }
  }
);
//получение parcitipate
export const getParcitipateById = createAsyncThunk(
  "jackpot/get-parcitipate-by-id",
  async (id: number) => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/parcitipate`);
    if (req.ok) {
      const res = (await req.json()) as {
        id: number;
        config: ResponseJackpotParticipate;
      };
      if (res.config) {
        return res;
      } else {
        return undefined;
      }
    }
    return undefined;
  }
);
//Измение parcitipate
export const patchParcitipateById = createAsyncThunk(
  "jackpot/patch-parcitipate-by-id",
  async (props: { id: number; data: any }) => {
    const req = await agent.patch(`${DEFAULT_URL}/${props.id}/parcitipate`, {
      body: JSON.stringify(props.data),
    });
    if (req.ok) {
      const res = await req.json();
    }
  }
);

//создание most
export const postMostById = createAsyncThunk(
  "jackpot/post-most-by-id",
  async (props: { id: number; data: ResponseJackpotMost }) => {
    const req = await agent.post(`${DEFAULT_URL}/${props.id}/most-chance`, {
      body: JSON.stringify(props.data),
    });
    if (req.ok) {
      const res = await req.json();
    }
  }
);
//получение most
export const getMostById = createAsyncThunk(
  "jackpot/get-most-by-id",
  async (id: number) => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/most-chance`);
    if (req.ok) {
      const res = (await req.json()) as { config: ResponseJackpotParticipate };
      return res.config;
    }
    return undefined;
  }
);
//Измение most
export const patchMostById = createAsyncThunk(
  "jackpot/patch-most-by-id",
  async (props: { id: number; data: ResponseJackpotMost }) => {
    await agent.patch(`${DEFAULT_URL}/${props.id}/most-chance`, {
      body: JSON.stringify(props.data),
    });
  }
);
// how to win

export const gatHowToWin = createAsyncThunk(
  "jackpot/get-how-to-win",
  async (id: number): Promise<undefined | ResponseHowToWin> => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/how-to-win`);
    if (req.ok) {
      const res = await req.json();
      return res;
    }
    return undefined;
  }
);
export const postHowToWinGlobal = createAsyncThunk(
  "jackpot/post-how-to-win-global",
  async (data: { globalSettings: HowToWinGlobal; jackpotId: number }) => {
    await agent.post(`${DEFAULT_URL}/how-to-win/create-global-settings`, {
      body: JSON.stringify(data),
    });
  }
);

export const patchHowToWinGlobal = createAsyncThunk(
  "jackpot/patch-how-to-win-global",
  async (data: { globalSettings: HowToWinGlobal; jackpotId: number }) => {
    await agent.patch(`${DEFAULT_URL}/how-to-win/update-global-settings`, {
      body: JSON.stringify(data),
    });
  }
);
export const postHowToWinTranslation = createAsyncThunk(
  "jackpot/post-how-to-win-translation",
  async (data: { jackpotId: number; lang: ResponseHowToWinlangs }) => {
    await agent.post(`${DEFAULT_URL}/how-to-win/add-lang`, {
      body: JSON.stringify(data),
    });
  }
);
export const patchHowToWinTranslation = createAsyncThunk(
  "jackpot/patch-how-to-win-translation",
  async (data: { jackpotId: number; lang: ResponseHowToWinlangs }) => {
    await agent.patch(`${DEFAULT_URL}/how-to-win/update-lang`, {
      body: JSON.stringify(data),
    });
  }
);

// how to win end

export const getProfiles = createAsyncThunk(
  "jackpot/get-profile-list",
  async () => {
    const req = await agent.get(`${DEFAULT_URL}/profile/list`);
    if (req.ok) {
      const res = (await req.json()) as { list: IJackpotProfile[] };
      return res.list;
    }
    return [];
  }
);
export const postProfile = createAsyncThunk(
  "jackpot/post-profile",
  async (data: IJackpotProfile, thunksApi) => {
    const req = await agent.post(`${DEFAULT_URL}/profile`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      thunksApi.dispatch(getProfiles());
    }
  }
);
export const patchProfile = createAsyncThunk(
  "jackpot/patch-profile",
  async (data: IJackpotProfile, thunksApi) => {
    const req = await agent.patch(`${DEFAULT_URL}/profile/${data.id}`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      thunksApi.dispatch(getProfiles());
    }
  }
);
export const getProfileById = createAsyncThunk(
  "jackpot/get-profile",
  async (id: string, thunksApi) => {
    const req = await agent.get(`${DEFAULT_URL}/profile/${id}`);
    if (req.ok) {
      const res = (await req.json()) as IJackpotProfile;
      return res;
    }
  }
);

export const getProfileConfigList = createAsyncThunk(
  "jackpot/get-profile-config",
  async (id: number) => {
    const query = encodeGetParams({ page: 1, limit: 250, profileId: id });
    const req = await agent.get(`${DEFAULT_URL}/profile/config/list${query}`);
    if (req.ok) {
      const res = (await req.json()) as { list: { gameEntityId: number }[] };
      return res.list;
    }
    return [];
  }
);

export const postProfileConfigList = createAsyncThunk(
  "jackpot/post-profile-config",
  async (data: {
    configList: {
      profileId: number;
      gameEntityId: number;
    }[];
  }) => {
    await agent.post(`${DEFAULT_URL}/profile/config`, {
      body: JSON.stringify(data),
    });
  }
);

export const patchProfileConfigList = createAsyncThunk(
  "jackpot/patch-profile-config",
  async (props: {
    data: {
      configList: {
        profileId: number;
        gameEntityId: number;
      }[];
    };
    id: number;
  }) => {
    const req = await agent.patch(`${DEFAULT_URL}/profile/config/${props.id}`, {
      body: JSON.stringify(props.data),
    });
  }
);

export const getElementOrder = createAsyncThunk(
  "jackpot/get-case-page-element-order",
  async (id: number) => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/element-order`);
    if (req.ok) {
      const res = (await req.json()) as ResponseElementOrder;
      return res.config;
    }
  }
);

export const postElementOrder = createAsyncThunk(
  "jackpot/post-case-page-element-order",
  async (props: { data: ElementOrder[]; id: number }) => {
    await agent.post(`${DEFAULT_URL}/${props.id}/element-order`, {
      body: JSON.stringify({ config: props.data }),
    });
  }
);

export const patchElementOrder = createAsyncThunk(
  "jackpot/patch-case-page-element-order",
  async (props: { data: ElementOrder[]; id: number }) => {
    const body = {
      config: props.data.map((item, index) => ({
        title: item.title,
        position: index + 1,
      })),
    };
    await agent.patch(`${DEFAULT_URL}/${props.id}/element-order`, {
      body: JSON.stringify(body),
    });
  }
);

//[GET] /organization/game/jackpot/{jackpotId}/end-notification
export const getNotification = createAsyncThunk(
  "jackpot/get-notification",
  async (jackpotId: number) => {
    const res = await agent.get(`${DEFAULT_URL}/${jackpotId}/end-notification`);
    if (res.ok) {
      const req = (await res.json()) as ResponseNotification;
      return req;
    }
  }
);
//[POST] /organization/game/jackpot/end-notification/create-global-settings
export const postGlobalNotification = createAsyncThunk(
  "jackpot/post-global-notification",
  async (data: {
    jackpotId: number;
    globalSettings: RequestNotificationGlobal;
  }) => {
    await agent.post(`${DEFAULT_URL}/end-notification/create-global-settings`, {
      body: JSON.stringify(data),
    });
  }
);
//[PATCH] /organization/game/jackpot/end-notification/update-global-settings
export const patchGlobalNotification = createAsyncThunk(
  "jackpot/patch-global-notification",
  async (data: {
    jackpotId: number;
    globalSettings: RequestNotificationGlobal;
  }) => {
    await agent.patch(
      `${DEFAULT_URL}/end-notification/update-global-settings`,
      {
        body: JSON.stringify(data),
      }
    );
  }
);
//[POST] /organization/game/jackpot/end-notification/add-lang
export const postNotificationTranslation = createAsyncThunk(
  "jackpot/post-notification-translation",
  async (data: { jackpotId: number; lang: ResponseNotificationLangs }) => {
    await agent.post(`${DEFAULT_URL}/end-notification/add-lang`, {
      body: JSON.stringify(data),
    });
  }
);
//[PATCH] /organization/game/jackpot/end-notification/update-lang
export const patchNotificationTranslation = createAsyncThunk(
  "jackpot/patch-notification-translation",
  async (data: { jackpotId: number; lang: ResponseNotificationLangs }) => {
    await agent.patch(`${DEFAULT_URL}/end-notification/update-lang`, {
      body: JSON.stringify(data),
    });
  }
);

export const name = createAsyncThunk("jackpot/name", async () => {
  return null;
});
